import React from 'react'

import styles from './styles.module.scss'

interface Props {
  children: React.ReactNode
}

const Component: React.FC<Props> = ({ children }) => {
  return <main className={styles.layout}>{children}</main>
}

export const HomeLayout = React.memo(Component)
