import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { common } from '@/data/localisation'
import { GetStaticPropsContext } from 'next'
import { HomePage } from '@/pages/home'
import { HomeLayout } from '@/layouts/home'
import { createInstance } from '@/services/api'
import { apiUrl } from '@/utils/urls'

export default function Home(props: any) {
  return (
    <HomeLayout>
      <HomePage {...props} />
    </HomeLayout>
  )
}

export async function getStaticProps(ctx: GetStaticPropsContext) {
  const api = createInstance(apiUrl())

  const summary = await api.partner.getSummary()

  return {
    props: {
      summary,
      ...(await serverSideTranslations(ctx.locale || 'ru', [...common]))
    },
    revalidate: 60000 * 10
  }
}
