import React from 'react'

import styles from './styles.module.scss'
import { Text } from '@/components/text'
import { Button } from '@/components/button'
import { Icon } from '@/components/icons/icon'

interface Props {
  summary: {
    earned: number
    registered: number
    withdrawn: number
  }
}

const Component: React.FC<Props> = ({ summary }) => {
  const authHandler = () => {
    window.location.href = `${process.env.NEXT_PUBLIC_API_ORIGIN}/api/auth`
  }

  return (
    <div className={styles.homePage__content}>
      <section className={styles.content__header}>
        {/*<img className={styles.content__background} src='/images/home/header-background.png' />*/}

        <div className={styles.header_info__wrapper}>
          <a href='/' className={styles.header__link}>
            <img className={styles.header__logo} src='/images/logo.svg' alt='skinbox logo' />
          </a>

          <div className={styles.header__title}>
            <Text variant='h1' tag='h1' color='white'>
              Зарабатывайте вместе со{' '}
              <Text variant='h1' tag='span' color='gold-secondary'>
                SKINBOX
              </Text>
            </Text>
          </div>

          <div className={styles.header__description}>
            <Text variant='h6' color='primary'>
              Теперь вам не обязательно иметь большую площадку с<br />
              аудиторией, чтобы вы могли сотрудничать на подобных условиях.
              <br />
              Сделать это может каждый!
            </Text>
          </div>

          <div className={styles.header__controls}>
            <Button
              color='gold-secondary'
              after={<Icon name='steam' color='gray' />}
              fit-content
              onClick={authHandler}>
              Войти через Steam
            </Button>
            {/*<Button color='gold'>*/}
            {/*  <Icon name='mail' color='gray' />*/}
            {/*</Button>*/}
          </div>
        </div>
        <div className={styles.content__background} />
      </section>

      <section className={styles.content__about}>
        <div className={styles.about__title}>
          <Text variant='h2' color='white'>
            Как мы работаем?
          </Text>
        </div>

        <div className={styles.about__description}>
          <div className={styles.description__item}>
            <div className={styles.item__image}>
              <img src='/images/home/about-1.png' alt='Создавайте промокод' />
            </div>
            <div className={styles.item__title}>
              <Text variant='h5' color='gold-secondary'>
                Создавайте промокод
              </Text>
            </div>
            <div className={styles.item__description}>
              <Text variant='h6' color='primary'>
                В нашей реферальной системе вы создаете нужное количество промокодов для
                использования на разных площадках.
              </Text>
            </div>
          </div>

          {/*<div className={styles.description__delimiter} />*/}

          <div className={styles.description__item}>
            <div className={styles.item__image}>
              <img src='/images/home/about-2.png' alt='Делитесь с аудиторией' />
            </div>
            <div className={styles.item__title}>
              <Text variant='h5' color='gold-secondary'>
                Делитесь с аудиторией
              </Text>
            </div>
            <div className={styles.item__description}>
              <Text variant='h6' color='primary'>
                Мы предоставляем самые разные форматы рекламных материалов, используйте их на своё
                усмотрение.
              </Text>
            </div>
          </div>

          {/*<div className={styles.description__delimiter} />*/}

          <div className={styles.description__item}>
            <div className={styles.item__image}>
              <img src='/images/home/about-3.png' alt='Получайте прибыль' />
            </div>
            <div className={styles.item__title}>
              <Text variant='h5' color='gold-secondary'>
                Получайте прибыль
              </Text>
            </div>
            <div className={styles.item__description}>
              <Text variant='h6' color='primary'>
                Ваша активность по продвижению промокодов напрямую влияет на заработок! А мы
                гарантируем регулярные выплаты.
              </Text>
            </div>
          </div>
        </div>
      </section>

      <section className={styles.content__statistics}>
        <div className={styles.statistics__title}>
          <Text variant='h2' color='white'>
            Статистика
          </Text>
        </div>

        <div className={styles.statistics__informer}>
          <div className={styles.informer__item}>
            <div className={styles.item__value}>
              <Text variant='h1' color='gold-secondary'>
                {summary.registered}
              </Text>
            </div>
            <div className={styles.item__title}>
              <Text variant='h6' color='primary'>
                Подключено партнёров
              </Text>
            </div>
          </div>

          <div className={styles.informer__item}>
            <div className={styles.item__value}>
              <Text variant='h1' color='gold-secondary'>
                {summary.earned}
              </Text>
            </div>
            <div className={styles.item__title}>
              <Text variant='h6' color='primary'>
                Заработано всего
              </Text>
            </div>
          </div>

          <div className={styles.informer__item}>
            <div className={styles.item__value}>
              <Text variant='h1' color='gold-secondary'>
                {summary.withdrawn}
              </Text>
            </div>
            <div className={styles.item__title}>
              <Text variant='h6' color='primary'>
                Выведено всего
              </Text>
            </div>
          </div>
        </div>

        <div className={styles.statistics__footer} />
      </section>

      <footer>
        <Text variant='h6' color='primary'>
          © SKINBOX. All right reserved.
        </Text>
      </footer>
    </div>
  )
}

export const HomePage = React.memo(Component)
